import { useEffect, useState } from 'react';
import NavigationMenuElement from './NavigationMenuElement';
import ReactDOM from 'react-dom';
import { NavigationItem } from './navigation-props';
import { useI18n } from 'next-localization';
import LanguageCurrency from './LanguageCurrency';
import { useAppDispatch } from 'lib/store/hooks';
import { navigationActions } from 'lib/navigation/navigation-slice';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type NavigationGlobalNavigationMobileProps = {
  menuItems: NavigationItem[];
  activeIndex: number;
  onMenuClick: (index: number) => void;
  renderingId?: string | undefined;
};

const NavigationGlobalNavigationMobile = (
  props: NavigationGlobalNavigationMobileProps
): JSX.Element | null => {
  const [isClient, setIsClient] = useState(false);
  const { t } = useI18n();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsClient(true);
  }, []);

  // const onClickCloseHandler = (): void => {
  //   props.onMenuClick(-1);
  // };

  const onClickGlobalNavigationHandler = (): void => {
    props.onMenuClick(-1);
    const bodyDiv = document.getElementById('body');
    bodyDiv?.classList.remove('!overflow-hidden');
    dispatch(navigationActions.toggleMenuModal());
  };

  const mobileGlobalMenuContent = (
    <nav className="bg-neutral-medium font-avidwalsheim text-white px-5 pt-8 pb-16">
      <div className="flex justify-between items-center">
        <div className="font-bold text-xl pl-4">{t('Menu')}</div>
        <div>
          <button type="button" onClick={onClickGlobalNavigationHandler}>
            <FontAwesomeIcon icon={faXmark} className="text-white w-full pr-4" />
            <span className="sr-only">{t('Close')}</span>
          </button>
        </div>
      </div>

      <div className="mt-6 pl-4">
        <LanguageCurrency />
      </div>
      {props.menuItems && <div className="border-b-[1px] border-neutral-medium-dark mt-6" />}
      <ul role="menubar" className="justify-center items-center text-lg">
        {props.menuItems &&
          props.menuItems.map((menuItem, index) => (
            <li
              key={index}
              role="menuitem"
              className={`leading-11 border-t-avidgray200 ${
                index === props.activeIndex ? 'bg-neutral-medium' : ''
              }`}
            >
              <NavigationMenuElement
                navigationItem={menuItem}
                active={props.activeIndex === index}
                onMenuClick={(): void => props.onMenuClick(index)}
                className="pl-4"
                mobile={true}
                onLinkClick={onClickGlobalNavigationHandler}
              />
              <div
                className={`border-b-[1px] border-neutral-medium-dark ${
                  props.activeIndex === index ? 'hidden' : ''
                }`}
              />
            </li>
          ))}
      </ul>
    </nav>
  );

  if (isClient) {
    const modalRoot = document.getElementById('mobile-global-navigation');
    if (modalRoot) {
      return ReactDOM.createPortal(mobileGlobalMenuContent, modalRoot);
    }
  } else {
    return null;
  }

  return null;
};

export default NavigationGlobalNavigationMobile;
