import {
  NavigationMegaMenuGroup,
  NavigationGroup,
  NavigationItem,
  NavigationClickableMenuItem,
} from './navigation-props';
import NavigationMegaMenu from './NavigationMegaMenu';
import NavigationSimpleMenu from './NavigationSimpleMenu';

type NavigationMenuElementProps = NavigationClickableMenuItem & {
  navigationItem: NavigationItem;
  active: boolean;
  mobile?: boolean;
};

const NavigationMenuElement = (props: NavigationMenuElementProps): JSX.Element => {
  const { navigationItem, className } = props;
  if ((navigationItem as NavigationMegaMenuGroup).mega) {
    return (
      <NavigationMegaMenu
        onMenuClick={props.onMenuClick}
        navigationItem={navigationItem as NavigationMegaMenuGroup}
        className={className}
        active={props.active}
        mobile={props.mobile}
        onLinkClick={props.onLinkClick}
      />
    );
  } else if ((navigationItem as NavigationGroup).group) {
    return (
      <NavigationSimpleMenu
        onMenuClick={props.onMenuClick}
        navigationItem={navigationItem as NavigationGroup}
        className={className}
        active={props.active}
        mobile={props.mobile}
        onLinkClick={props.onLinkClick}
      />
    );
  }
  return <></>;
};

export default NavigationMenuElement;
