import { getLanguageName } from 'lib/navigation/navigation-utils';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { navigationActions } from '../../../lib/navigation/navigation-slice';

const LanguageCurrency = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const currencyIsoCode = useAppSelector((state) => state.cart?.currencyIsoCode);
  const navigationState = useAppSelector((state) => state.navigation);
  const currentLanguageName = getLanguageName(navigationState.currentLanguage);

  const onClickHandler = (): void => {
    dispatch(navigationActions.toggleSwitcherModal());
  };

  return (
    <button
      className="text-neutral-light flex items-center hover:text-neutral-lighter rounded active:text-neutral-lightest focus:outline focus:outline-2 focus:outline-avidgreen focus:outline-offset-2"
      onClick={onClickHandler}
    >
      <span className="underline">{currentLanguageName}</span>
      <span className="px-1">{' | '}</span>
      <span className="underline">{currencyIsoCode}</span>
    </button>
  );
};

export default LanguageCurrency;
