import {
  NavigationGroup,
  NavigationItem,
  NavigationClickableMenuItem,
  NavigationLink,
} from './navigation-props';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type NavigationMegaMenuProps = NavigationClickableMenuItem & {
  navigationItem?: NavigationGroup;
  active: boolean;
  mobile?: boolean;
};

const NavigationSimpleMenu = (props: NavigationMegaMenuProps): JSX.Element => {
  const heading = (props.navigationItem as NavigationGroup)?.text;
  const children = props.navigationItem?.children?.results as NavigationItem[];
  const isGroup = props.navigationItem?.group?.value;

  const onClickMenuHandler = (): void => {
    props.onMenuClick();
  };

  const simpleMenuContent = children && props.active && (
    <ul
      onClick={(e): void => e.stopPropagation()}
      className="flex flex-col space-y-3 absolute bg-neutral-medium text-left w-fit h-auto p-6 text-base text-neutral-lightest font-[500] hover:cursor-auto"
    >
      {children.map((child, index) => (
        <li key={index}>
          <NextLink
            field={(child as NavigationLink)?.link?.jsonValue}
            onClick={onClickMenuHandler}
          />
        </li>
      ))}
    </ul>
  );

  const mobileSimpleMenuContent = children && props.active && props.mobile && (
    <ul className="flex flex-col space-y-3 bg-neutral-dark text-left h-auto p-4 mt-2 text-base text-neutral-lightest font-[500]">
      {children.map((child, index) => (
        <li key={index}>
          <NextLink
            field={(child as NavigationLink)?.link?.jsonValue}
            onClick={props.onLinkClick}
            className="text-left"
          />
        </li>
      ))}
    </ul>
  );

  return (
    <>
      {isGroup ? (
        <>
          <div
            className={`${props.className ?? ''} flex items-center justify-between`}
            onClick={onClickMenuHandler}
          >
            <Text
              field={heading}
              tag="div"
              className={`font-avidwalsheimbold ${
                props.mobile ? 'w-full text-base leading-11' : ''
              }`}
            />
            <div
              className={`w-3 h-3 flex items-center ${
                props.mobile ? 'text-accent-cool pr-4 w-8 h-7' : 'ml-2'
              }`}
            >
              <FontAwesomeIcon icon={props.active ? faChevronUp : faChevronDown} />
            </div>
          </div>
          {props.mobile ? mobileSimpleMenuContent : simpleMenuContent}
        </>
      ) : (
        <>
          <div
            className={`${props.className ?? ''} flex items-center justify-between`}
            onClick={onClickMenuHandler}
          >
            <NextLink
              field={(props.navigationItem as NavigationLink)?.link?.jsonValue}
              onClick={props.onLinkClick}
              className={`font-avidwalsheimbold ${
                props.mobile ? 'w-full text-base leading-11' : ''
              }`}
            />
          </div>
        </>
      )}
    </>
  );
};

export default NavigationSimpleMenu;
